const useDeepL = () => {

  const translate = async( text: string ) => {
    if( localStorage.getItem( 'abilibeeToken' ) === null ) return text

    const userStore = useUserStore()
    await userStore.init()

    if( userStore.user?.language === 'en_US' ||  ! userStore?.user?.language ) {
      return text
    }

    const res = await Http.post( '/deepl', {
      text,
      language: userStore?.user?.language.split( '_' )[0]
    } )

    return JSON.parse( res ).translations[0].text
  }

  return {
    translate
  }

}

export default useDeepL