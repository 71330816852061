<template>
  <h1 class="text-3xl font-bold px-4 mb-5">
    {{ t( 'favorites' ) }}
  </h1>

  <ContentLoader
    v-if="!favouriteStore.favourites"
  />
  <div
    v-for="favorite,i in favouriteStore.favourites"
    v-else
    :key="favorite.ID"
    class="border-b border-[#BBBBBB] py-4"
    :class="{'border-t': i === 0 }"
  >
    <div
      class="px-4 flex gap-4 items-center justify-between"
    >
      <button
        class="flex items-center text-start"
        :target="favorite.postType !== 'tribe_events' ? '_blank' : '_self'"
        @click="handleFavouriteClick( favorite )"
      >
        <BaseIcon
          v-if="favorite.postType !== 'tribe_events'"
          class="flex-shrink-0 text-[#BBBBBB] mr-4"
          :icon="getIcon( favorite.type ?? '' )"
        />
        <ion-icon
          v-else
          :icon="calendar"
          class="text-[19pt] flex-shrink-0 text-[#BBBBBB] mr-4"
        />

        <div>
          {{ favorite.title }}
        </div>
      </button>

      <ion-icon
        :icon="star"
        class="text-[19pt] flex-shrink-0 ml-3 text-brand-dark-yellow"
        @click="toggleFavorite( favorite.ID )"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonIcon } from '@ionic/vue'
import {
  calendar,
  star
} from 'ionicons/icons'
import externalLink from '@/assets/images/icons/external-link.vue'
import text from '@/assets/images/icons/text.vue'
import youtube from '@/assets/images/icons/youtube.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import ContentLoader from '@/components/ContentLoader.vue'

const { t } = useI18n()
const favouriteStore = useFavouriteStore()

onMounted( async () => {
  await favouriteStore.init()
} )

const router = useRouter()
const emit = defineEmits( [ 'close' ] )

const handleFavouriteClick = ( favorite: Exclude<typeof favouriteStore.favourites, undefined>[0] ) => {
  if( favorite.postType === 'tribe_events' ) {
    router.push( '/event/' + favorite.ID )
  } else {
    window.open( favorite.url ?? '#', '_blank' )
  }
  emit( 'close' )
}

const getIcon = ( type: string ) => {
  switch ( type ) {
  case 'video':
    return youtube
  case 'youtube':
    return youtube
  case 'article':
    return text
  case 'link':
    return externalLink
  default:
    return text
  }
}

const toggleFavorite = async( id: number ) => {
  await favouriteStore.removeFavourite( id )
}
</script>