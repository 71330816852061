<template>
  <portal
    to="sideblade"
  >
    <div
      class="w-screen h-screen z-40 bg-black transition-opacity ease-in-out duration-300"
      :class="{'opacity-0': !isOpen, 'opacity-30': isOpen}"
      @click="close()"
    />
    <div
      class="absolute z-50 bg-[#FFFDF7] w-11/12 max-w-md right-0 h-screen transform transition-transform duration-300 ease-in-out pt-4 pb-20 text-left overflow-auto"
      :class="{'translate-x-0': isOpen, 'translate-x-full': !isOpen, 'left-shadow': isOpen}"
    >
      <span
        class="w-full underline text-lightgray text-sm block mb-6 px-6 font-bold cursor-pointer"
        @click="close()"
      >
        {{ t( 'close' ) }}
      </span>

      <div :class="{'px-6': !fullContent}">
        <slot />

        <div
          v-if="props.showButtons"
          class="flex flex-col gap-3 mt-8"
        >
          <BaseButton
            v-if="!hideOk"
            :title="okText"
            variant="primary"
            size="small"
            @click="ok()"
          />
          <BaseButton
            v-if="!hideCancel && !showDelete"
            :title="t( cancelText )"
            variant="secondary"
            class="!outline-none underline text-lightgray"
            size="small"
            @click="close()"
          />
          <BaseButton
            v-if="showDelete"
            :title="t( 'delete' )"
            variant="remove"
            size="small"
            @click="remove()"
          />
        </div>
      </div>
    </div>
  </portal>
</template>

<script setup lang="ts">
import {
  onMounted,
  ref
} from 'vue'
import BaseButton from '@/components/BaseButton.vue'

const { t } = useI18n()

const props = withDefaults( defineProps<{
  showButtons?: boolean,
  hideCancel?:boolean,
  hideOk?:boolean,
  okText?: string,
  cancelText?: string,
  fullContent?: boolean
  showDelete?: boolean
}>(), {
  showButtons: false,
  hideCancel: false,
  hideOk: false,
  okText: 'Save',
  cancelText: 'Cancel',
  fullContent: false,
  showDelete: false
} )

const isOpen = ref( false )

const emit = defineEmits<{
  ( event: 'close' ): void,
  ( event: 'ok' ): void,
  ( event: 'delete' ): void
}>()

const remove = () => {
  emit( 'delete' )
}

const close = () => {
  isOpen.value = false

  setTimeout( () => {
    emit( 'close' )
  }, 300 )
}

const ok = () => {
  emit( 'ok' )
  close()
}

onMounted( () => {
  setTimeout( () => {
    isOpen.value = true
  }, 1 )
} )

defineExpose( {
  close,
} )

</script>

<style>
.left-shadow {
  box-shadow: -10px 0px 10px 0px rgba(0,0,0,0.1);
}
</style>