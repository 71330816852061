import axios, { AxiosRequestConfig } from 'axios'
import config from '@/router/config'
import getToken from '@/router/getToken'

const apiClient = axios.create( {
  baseURL: config.apiBase,
  timeout: 40000,
  params: {
    access_token: getToken()
  },
} )

const defaultError = ( error: any ) => console.log( error )

class Http {

  addToken = ( config: AxiosRequestConfig ) => {
    if ( config.params ) {
      config.params.access_token = getToken()
    } else {
      config.params = {}
      config.params.access_token = getToken()
    }
    return config
  }

  defaultConfig = {
    params: {
      access_token: getToken()
    }
  }

  /**
   * Destroy the session and log the user out.
   */
  logout = async () => {
    try {
      const response = await apiClient.post(
        `${config.rootURL}/oauth/destroy`,
        {
          oauth: 'me',
          access_token: getToken(),
        }
      )
      return response.data
    } catch ( error: any ) {
      return error.response.data
    }
  }

  /**
   * Check that the access token is valid.
   */
  validateSession = async () => {
    try {
      const response = await apiClient.get(
        config.rootURL,
        {
          params: {
            oauth: 'me',
            access_token: getToken(),
          }
        }
      )
      return response.data
    } catch ( error: any ) {
      console.log( 'error', error )
      return error?.response?.data
    }
  }

  /**
   * Check that the access token is valid.
   */
  getRefreshToken = async ( refreshToken: string ) => {
    try {
      const response = await axios( {
        method: 'post',
        url: config.authURI,
        headers: {
          'Authorization': 'Basic dFJoMjNkdVVXY0Z4QnZwSTZ0dXBZNnZXWFJTczRuWW9lNlZOUFVBODpMN3JIN0lWSEhPN1VSeG9VeUtrTk5DMVowR3ZXdnVac0thanJlVnBu',
        },
        data: {
          'grant_type': 'refresh_token',
          'refresh_token': refreshToken,
        }
      } )
      if ( response.data ) this.defaultConfig.params.access_token = response.data.access_token
      return response.data
    } catch ( error: any ) {
      return error.response?.data
    }
  }

  /**
   * Log the user in using OAuth2.
   *
   * @param email The user entered email address.
   * @param password The user entered password.
   */
  login = async ( email: string, password: string ) => {
    try {
      const response = await axios( {
        method: 'post',
        url: config.authURI,
        headers: {
          'Authorization': 'Basic dFJoMjNkdVVXY0Z4QnZwSTZ0dXBZNnZXWFJTczRuWW9lNlZOUFVBODpMN3JIN0lWSEhPN1VSeG9VeUtrTk5DMVowR3ZXdnVac0thanJlVnBu',
        },
        data: {
          'grant_type': 'password',
          'username': email,
          'password': password
        }
      } )
      return response.data
    } catch ( error: any ) {
      return error.response?.data
    }
  }

  /**
   * "POST" wrapper for our Http client.
   *
   * @param path The URL path to be queried.
   * @param data The data to be sent along with the request.
   * @param cb A callback function to execute on error.
   */
  post = async (
    path: string,
    data: any,
    config: AxiosRequestConfig = this.defaultConfig,
    cb: ( error: any ) => void = defaultError
  ) => {
    try {
      const response = await this.performPostRequest( path, data, config )
      return response
    } catch ( error ) {
      if ( await this.validateRequest() ) {
        const response = await this.performPostRequest( path, data, config )
        return response
      } else logout()
      cb( error )
      return error
    }
  }

  /**
   * Perform the actual request.
   *
   * @param path The URL path to be queried.
   * @param data The data to be sent along with the request.
   * @param config The inherited Axios config.
   * @returns The data from the response.
   */
  performPostRequest = async ( path: string, data: any, config: AxiosRequestConfig ) => {
    const response = await apiClient.post(
      path,
      data,
      this.addToken( config )
    )
    return response.data
  }

  validateRequest = async () => {
    const response = await this.validateSession()
    if ( response.error ) {
      const refreshToken = localStorage.getItem( 'refreshToken' )
      if ( refreshToken ) {
        const response = await this.getRefreshToken( refreshToken )
        if ( response.error ) return false
        localStorage.setItem( 'abilibeeToken', response.access_token )
        localStorage.setItem( 'refreshToken', response.refresh_token )
        localStorage.setItem(
          'tokenExpiry',
          String( Math.round( new Date().getTime() / 1000 + 86400 ) )
        )
        return true
      } else return false
    } else return true
  }

  performGetRequest = async ( path: string, params: AxiosRequestConfig ) => {
    const response = await apiClient.get(
      path,
      this.addToken( params )
    )
    return response.data
  }

  /**
   * "GET" wrapper for our Http client.
   *
   * @param path The URL path to be queried.
   * @param AxiosRequestConfig The params for the "GET" request.
   * @param cb A callback function to execute on error.
   */
  get = async (
    path: string,
    params: AxiosRequestConfig = this.defaultConfig,
    cb: ( error: any ) => void = defaultError
  ) => {
    try {
      const response = await this.performGetRequest( path, params )
      return response
    } catch ( error ) {
      if ( await this.validateRequest() ) {
        const response = await this.performGetRequest( path, params )
        return response
      } else logout()
      cb( error )
    }
  }

  /**
   * Perform the actual request.
   *
   * @param path The URL path to be queried.
   * @param data The data to be sent along with the request.
   * @param config The inherited Axios config.
   * @returns The data from the response.
   */
  performPutRequest = async ( path: string, data: any ) => {
    const response = await apiClient.put(
      path,
      data,
      {
        params: {
          access_token: getToken()
        }
      }
    )
    return response.data
  }

  /**
   * Perform the actual request.
   *
   * @param path The URL path to be queried.
   * @param data The data to be sent along with the request.
   * @returns The data from the response.
   */
  performPatchRequest = async ( path: string, data: any ) => {
    const response = await apiClient.patch(
      path,
      data,
      {
        params: {
          access_token: getToken()
        }
      }
    )
    return response.data
  }

  /**
   * "PUT" wrapper for our Http client.
   *
   * @param path The URL path to be queried.
   * @param cb A callback function to execute on error.
   */
  put = async (
    path: string,
    data: Record<string, unknown>,
    cb: ( error: any ) => void = defaultError
  ) => {
    try {
      const response = await this.performPutRequest( path, data )
      return response
    } catch ( error ) {
      if ( await this.validateRequest() ) {
        const response = await this.performPutRequest( path, data )
        return response
      } else logout()
      cb( error )
      return error
    }
  }

  /**
   * "PATCH" wrapper for our Http client.
   *
   * @param path The URL path to be queried.
   * @param cb A callback function to execute on error.
   */
  patch = async (
    path: string,
    data: Record<string, unknown>,
    cb: ( error: any ) => void = defaultError
  ) => {
    try {
      const response = await this.performPatchRequest( path, data )
      return response
    } catch ( error ) {
      if ( await this.validateRequest() ) {
        const response = await this.performPatchRequest( path, data )
        return response
      } else logout()
      cb( error )
      return error
    }
  }

  /**
   * Perform the actual request.
   *
   * @param path The URL path to be queried.
   * @param data The data to be sent along with the request.
   * @returns The data from the response.
   */
  performDeleteRequest = async ( path: string, data: any ) => {
    const response = await apiClient.delete(
      path,
      {
        data,
        params: {
          access_token: getToken()
        }
      }
    )
    return response.data
  }

  /**
   * "DELETE" wrapper for our Http client.
   *
   * @param path The URL path to be queried.
   * @param cb A callback function to execute on error.
   */
  delete = async (
    path: string,
    data: Record<string, unknown>| null = null,
    cb: ( error: any ) => void = defaultError
  ) => {
    try {
      const response = await this.performDeleteRequest( path, data )
      return response
    } catch ( error ) {
      if ( await this.validateRequest() ) {
        const response = await this.performDeleteRequest( path, data )
        return response
      } else logout()
      cb( error )
      return error
    }
  }

}

export default new Http()
