<template>
  <div
    v-if="selectedNotification === -1"
    class="relative"
  >
    <div class="w-1/2 absolute right-4 -top-11">
      <BaseButton
        :title="t( 'notifications.markAllRead' )"
        variant="secondary"
        size="xs"
        icon="close"
        @click="readNotifications( notifications?.filter( n => !n.read ).map( n => n.ID ) ?? [] )"
      />
    </div>

    <h1 class="text-3xl font-bold px-4 mb-5">
      {{ t( 'notifications.title' ) }}
    </h1>

    <ContentLoader v-if="!notifications" />
    <div
      v-for="notification,i in notifications"
      v-else
      :key="notification.ID"
      class="border-b border-[#BBBBBB] py-4"
      :class="{'border-t': i === 0, 'bg-[#FFF5D6]': !notification.read}"
    >
      <div
        class="px-5"
        @click="openNotification( i )"
      >
        <div class="mb-1 text-sm text-brand-gray-light">
          {{ formatDate( notification.createdAt ? new Date( notification.createdAt ) : new Date(), 'M/D/YY @ h:mm A' ) }}
        </div>

        <div>
          {{ notification.title }}
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="notifications && selectedNotification >= 0"
    class="px-6"
  >
    <div class="text-brand-gray font-semibold text-sm">
      {{ formatDate( new Date( notifications[selectedNotification].createdAt ), 'h:mm A' ) }}
    </div>
    <h1 class="font-bold text-3xl mb-4">
      <div>
        {{ formatDate( new Date( notifications[selectedNotification].createdAt ), 'MMM D, YYYY' ) }}
      </div>
    </h1>

    <div>
      {{ notifications[selectedNotification].content }}
    </div>

    <BaseButton
      class="mt-10"
      :title="t( 'back' )"
      variant="secondary"
      size="small"
      @click="selectedNotification = -1"
    />
  </div>
</template>

<script setup lang="ts">
import { formatDate } from '@vueuse/core'
import { Notification } from '@/assets/ts/interfaces/Notification'
import BaseButton from '@/components/BaseButton.vue'
import ContentLoader from '@/components/ContentLoader.vue'

const { t } = useI18n()
const userStore = useUserStore()
const { getNotifications, readNotifications } = useNotification()

const selectedNotification = ref( - 1 )
const notifications = ref<Notification[]>(  )

onMounted( async() => {
  await userStore.init()
  notifications.value = await getNotifications( userStore.selectedUserId )
} )

const openNotification = ( i: number ) => {
  if( notifications?.value?.[i] ){
    selectedNotification.value = i
    notifications.value[i].read = true
    readNotifications( [ notifications.value[i].ID ] )
  }
}

</script>