import {
  createRouter,
  createWebHistory
} from '@ionic/vue-router'
import type { RouteRecordRaw } from 'vue-router'

const BaseTabs = () => import( '@/components/BaseTabs.vue' )
const PageDashboard = () => import( '@/pages/PageDashboard.vue' )
const PageLibrary = () => import( '@/pages/PageLibrary.vue' )
const PageGuide = () => import( '@/pages/PageGuide.vue' )
const PageJournal = () => import( '@/pages/PageJournal.vue' )
const PageEvents = () => import( '@/pages/PageEvents.vue' )
const PageContacts = () => import( '@/pages/PageContacts.vue' )
const PageLogin = () => import( '@/pages/PageLogin.vue' )
const PageGlossary = () => import( '@/pages/PageGlossary.vue' )
const PageRegister = () => import( '@/pages/PageRegister.vue' )
const PageFAQ = () => import( '@/pages/PageFAQ.vue' )
const PageNotes = () => import( '@/pages/PageNotes.vue' )

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/register',
    component: PageRegister,
    beforeEnter( to, _, next ) {
      const step = to.query.step ? parseInt( to.query.step as string ) : 1
      if ( step && step > 1 ) {
        // Redirect to step 1.
        next( { path: '/register', query: { step: 1 } } )
      } else {
        // Continue with the navigation.
        next()
      }
    },
  },
  {
    path: '/',
    component: BaseTabs,
    children: [
      {
        path: '/login',
        component: PageLogin
      },
      {
        path: '/dashboard',
        component: PageDashboard,
      },
      {
        path: '/library',
        component: PageLibrary,
      },
      {
        path: '/guide',
        component: PageGuide,
      },
      {
        path: '/questionnaire/:id/:entry',
        component: () => import( '@/pages/PageQuestionnaire.vue' ),
        props: true
      },

      {
        path: '/journal',
        component: PageJournal,
      },
      {
        path: '/event/:id',
        component: () => import( '@/pages/PageEvent.vue' ),
        props: true
      },
      {
        path: '/events',
        component: PageEvents,
      },
      {
        path: '/contacts',
        component: PageContacts,
      },
      {
        path: '/glossary',
        component: PageGlossary,
      },
      {
        path: '/faq',
        component: PageFAQ,
      },
      {
        path: '/notes',
        component: PageNotes,
      },
      {
        path: '/profile',
        component: () => import( '@/pages/PageProfile.vue' )
      },
    ],
  }
]

const router = createRouter( {
  history: createWebHistory( import.meta.env.BASE_URL ),
  routes,
} )

router.beforeEach( async ( _, __, next ) => {
  if ( ! [ '/login', '/register' ].includes( window.location.pathname ) && ! localStorage.getItem( 'abilibeeToken' ) ) window.location.href = '/login'
  if ( [ '/login', '/' ].includes( window.location.pathname ) && localStorage.getItem( 'abilibeeToken' ) ) window.location.href = '/dashboard'
  next()
} )

export default router