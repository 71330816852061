import Http from '@/assets/ts/classes/Http'
import config from '@/router/config'

/**
 * Remove the items from localStorage and redirect to logout.
 */
const logout = async (): Promise<void> => {
  localStorage.removeItem( config.tokenName )
  localStorage.removeItem( 'tokenExpiry' )
  localStorage.removeItem( 'refreshToken' )
  await Http.logout()
  window.location.href = '/login?logged_out=true'
}

export default logout
