import type { Locale } from 'vue-i18n'
import { createI18n } from 'vue-i18n'

/**
 * Create the initial instance of vue-i18n.
 */
export const i18n = createI18n( {
  legacy: false,
  locale: '',
  fallbackLocale: 'en',
  messages: {},
} )

/**
 * An array representing available locales.
 */
export let availableLocales: string[] = []

/**
 * An array representing locales that have been loaded.
 */
export const loadedLanguages: string[] = []

/**
 * Set the language.
 *
 * @param lang The locale to set the language to.
 * @returns The locale.
 */
export function setI18nLanguage( lang: Locale ) {
  i18n.global.locale.value = lang as Locale
  if ( typeof document !== 'undefined' )
    document.querySelector( 'html' )?.setAttribute( 'lang', lang )
  return lang
}

/**
 * Load language files asynchronously.
 *
 * @param lang The language to load
 * @returns The resolved promise of the locale.
 */
export async function loadLanguageAsync( lang: string ): Promise<Locale> {

  const localesMap = Object.fromEntries(
    Object.entries( import.meta.glob( '../../locales/*.json' ) )
      .map( ( [ path, loadLocale ] ) => [ path.match( /([\w-]*)\.json$/ )?.[1], loadLocale ] ),
  ) as Record<Locale, () => Promise<{ default: Record<string, string> }>>

  availableLocales = Object.keys( localesMap )

  // If the same language.
  if ( i18n.global.locale.value === lang )
    return setI18nLanguage( lang )

  // If the language was already loaded.
  if ( loadedLanguages.includes( lang ) )
    return setI18nLanguage( lang )

  // If the language hasn't been loaded yet.
  console.log( 'messages', localesMap[lang] )
  console.log( 'await messages', await localesMap[lang]() )

  const messages = await localesMap[lang]()
  console.log( 'messages.default', messages )
  i18n.global.setLocaleMessage( lang, messages.default )
  console.log( 'messages.default', messages )
  loadedLanguages.push( lang )
  return setI18nLanguage( lang )
}